.icon_image {
  width: 8vh;
}

.display-flex {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 45vh;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

ion-header {
  display: block;
  position: relative;
  -ms-flex-order: -1;
  order: -1;
  width: 100%;
  z-index: 10;
  height: 90px;
}

ion-toolbar {
  --min-height: 90px;
}

ion-tab-bar {
  height: 75px;
}

ion-title {
  height: 80px;
  padding-top: 30px;
}

ion-item-divider {
  background-color: #dadada;
  color: white;
}
